<template>
  <div v-if="showPage" class="pay h-backgroud-color_body">
    <br />
    <div v-if="orderTime > 0">
      <div class="box h-text-center">
        <!-- <div class="h-font-xl h-flex h-row-center">
          <van-image v-if="orderTime > 0" :src="require('@/assets/images/icon/success.png')" style="height:20px;width:20px"/>
          <span class="h-m-l-5">{{ orderText }}</span>
        </div> -->
        <div>
          <div class="h-font-sm h-text-secondary-color h-m-t-5">请在<span class="h-price-color h-p-l-5 h-p-r-5">24时之前</span>完成支付</div>
          <div class="h-font-sm h-text-secondary-color h-m-t-2">逾期订单将自动取消</div>
        </div>
      </div>
      <div v-if="orderData" class="box">
        <div class="row h-flex h-row-between">
          <span>支付金额</span>
          <span class="h-price-color">￥{{ orderData.finalSaleAmount | amountFilter }}</span>
        </div>
        <div class="row h-flex h-row-between">
          <span>订单号</span>
          <span>{{ orderData.orderNo }}</span>
        </div>
        <div class="row h-flex h-row-between">
          <span>客户名称</span>
          <span>{{ orderData.customerName }}</span>
        </div>
        <div class="row h-flex h-row-between">
          <span>车牌号</span>
          <span>{{ orderData.plateNo }}</span>
        </div>
        <div class="row h-flex h-row-between">
          <span>服务名称</span>
          <span>{{ orderData.productNames }}</span>
        </div>
      </div>
      <div class="box">
        <van-radio-group v-model="payWay">
          <div class="h-flex h-row-between">
            <div class="h-flex">
              <van-image :src="require('@/assets/images/icon/weixin_pay.png')" style="height:30px;width:30px" />
              <div class="h-p-l-10">
                <div>微信支付</div>
              </div>
            </div>
            <div>
              <van-radio name="WXJS0007MIPP" checked-color="#57ccc2" />
            </div>
          </div>
        </van-radio-group>
      </div>
      <div class="btn h-flex h-row-around">
        <van-button v-if="orderTime > 0" round type="primary" class="h-flex-1 h-m-l-10 h-m-r-10" :loading="pageData.payLoading" @click="toPay">立即支付</van-button>
      </div>
    </div>
    <div v-else>
      <div class="box h-text-center">
        <div>
          <div class="h-font-sm h-text-secondary-color h-m-t-2">该订单已失效不能支付</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { request } from "@/api/service";
import { mapState } from "vuex";
import { wxOauth } from "@/api/common/user";
import goBack from "@/mixins/goBack";
function getUrlParam(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  const url = window.location.href.split("#")[0];
  const search = url.split("?")[1];
  if (search) {
    let r = search.substr(0).match(reg);
    if (r !== null) return unescape(r[2]);
    return null;
  } else {
    return null;
  }
}
export default {
  mixins: [goBack],
  filters: {
    amountFilter(value) {
      if (value) {
        return parseFloat(value).toFixed(2);
      }
      return "0.00";
    }
  },
  data() {
    return {
      pageData: {
        payLoading: false
      },
      orderId: null,
      preOrderId: null,
      payWay: "WXJS0007MIPP",
      interval: null,
      orderTime: 30,
      orderData: null
    };
  },
  computed: {
    ...mapState("user", ["info"]),
    showPage() {
      return !!getUrlParam("state");
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { orderId } = this.$route.query;
      if (!orderId) {
        return false;
      }
      this.orderId = orderId;
      try {
        const res = await request({
          url: `/afis-carservice/order/payOrderInfo/${orderId}`,
          method: "GET"
        });
        this.orderData = res;
        const coverages = res.coverages;
        if (coverages && coverages.length > 0) {
          this.orderData.productNames = coverages[0].productFeeName;
        }
        if (res && res.orderStatus === "7" && res.payStatus !== "1") {
          this.$router.replace({
            path: "/product/otherPay/info",
            query: {
              orderId: this.orderData.id
            }
          });
        }
        // this.calc()
      } catch (e) {}
    },
    toPay() {
      this.pageData.payLoading = true;
      request({
        url: `/afis-carservice/order/share/payment/${this.orderId}`,
        method: "POST",
        data: {}
      })
        .then(res => {
          this.pageData.payLoading = false;
          if (!res.preOrderId) {
            this.$toast("生成预支付单有误");
            return false;
          }
          this.preOrderId = res.preOrderId;
          if (this.preOrderId) {
            this.onLinePay();
          }
        })
        .catch(error => {
          this.pageData.payLoading = false;
        });
    },
    async onLinePay() {
      this.pageData.payLoading = true;
      const state = getUrlParam("state");
      const params = getUrlParam("code"); // 地址解析
      if (state === "wxAuth" && params) {
        const { thirdId } = await wxOauth(params);
        request({
          url: "/afis-payment/request/to/pay",
          method: "POST",
          data: {
            cid: this.payWay, // 支付方式
            preOrderId: this.preOrderId,
            // returnUrl:window.location.origin+`/#/product/pay/success?orderId=${this.orderId}`,
            openid: thirdId
          }
        })
          .then(res => {
            this.pageData.payLoading = false;
            const external = res.external;
            // eslint-disable-next-line no-undef
            WeixinJSBridge.invoke("getBrandWCPayRequest", external, resPay => {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              if (resPay.err_msg === "get_brand_wcpay_request:ok") {
                this.$router.replace({
                  path: "/product/otherPay/success",
                  query: {
                    orderId: this.orderData.id,
                    orderNo: this.orderData.orderNo
                  }
                });
              } else if (resPay.err_msg === "get_brand_wcpay_request:cancel") {
                this.$toast("已取消支付");
              } else {
                this.$toast("支付失败");
              }
            });
          })
          .catch(error => {
            this.pageData.payLoading = false;
          });
      }
    },
    calc(applyTime) {
      if (this.orderTime === -1) {
        this.clearTime();
        return;
      }
      const nowData = new Date().getTime();
      // 申请时间 + 30分钟的时间戳
      const validTime =
        dayjs(applyTime)
          .add(30, "minute")
          .unix() * 1000;
      if (nowData > validTime) {
        this.orderText = "订单已失效";
        this.orderTime = -1;
      } else {
        this.orderText = "订单提交成功！";
        this.orderTime = 30 - dayjs(new Date(nowData - this.orderData.applyTime)).format("m");
      }
    },
    calcTime() {
      this.interval = setInterval(this.calc, 60 * 1000);
    },
    clearTime() {
      this.interval && clearInterval(this.interval);
    }
  },
  beforeDestroy() {
    this.clearTime();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.pay {
  height: 100vh;
  padding: 0 10px;
  position: relative;
  font-size: 14px;
  .box {
    padding: 10px;
    margin-bottom: 10px;
    background-color: $white;
    border-radius: 5px;
  }
  .row {
    padding: 10px 5px;
    border-bottom: 1px solid #ebedf0;
  }
  .btn {
    margin-top: 30px;
  }
}
</style>
