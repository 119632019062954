export default {
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }
  },
  destoryed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
  methods: {
    goBack() {
      WeixinJSBridge.call("closeWindow");
    }
  }
};
